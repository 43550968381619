/*
Theme Name: Blog Template
Theme URI: https://projects.shariul.com/template/blog/
Description: Template for Blog Website
Author: Md. Shariul Islam
Author URI: https://www.shariul.com
Version: 1.0
Tags: responsive, columns, custom menu, etc.
*/

// Imports //

@use "sass:math";

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter.ttf") format("truetype-variations");
  font-weight: 1 999;
}

// Mixins //

@mixin responsive($screenWidth) {
  $breakpoint: math.div($screenWidth, 16) + em;
  @media only screen and (max-width: $breakpoint) {
    @content;
  }
}

// Variables //

// Bootstrap Overrides

$primary: #15aabf;
$secondary: #20c997;
// $success: crimson;
// $info: crimson;
// $warning: crimson;
// $danger: crimson;

$border-radius: 0.375rem;

$font-family-sans-serif: "Inter", sans-serif;

$link-decoration: none;

$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;

$fa-font-path: "../../node_modules/@fortawesome/fontawesome-free/webfonts"; // set $fa-font-path value
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "bootstrap/scss/bootstrap";

// Custom Styles //

.form-signin {
  max-width: 330px;
  padding: 1rem;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.container-96 {
  max-width: 960px;
  margin: 0 auto;
}

.header-page {
  background-image: url("../img/blog-header-bg.jpg");
  background-size: cover;
  background-position: top;
}
